var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "crumbs" },
      [
        _c(
          "el-breadcrumb",
          { attrs: { separator: "/" } },
          [
            _c("el-breadcrumb-item", [
              _c("i", { staticClass: "el-icon-lx-calendar" }),
              _vm._v(" 表单")
            ]),
            _c("el-breadcrumb-item", [_vm._v("编辑器")])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "container" },
      [
        _vm._m(0),
        _c("quill-editor", {
          ref: "myTextEditor",
          attrs: { options: _vm.editorOption },
          model: {
            value: _vm.content,
            callback: function($$v) {
              _vm.content = $$v
            },
            expression: "content"
          }
        }),
        _c(
          "el-button",
          {
            staticClass: "editor-btn",
            attrs: { type: "primary" },
            on: { click: _vm.submit }
          },
          [_vm._v("提交")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "plugins-tips" }, [
      _vm._v(
        "\n            Vue-Quill-Editor：基于Quill、适用于Vue2的富文本编辑器。\n            访问地址："
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/surmon-china/vue-quill-editor",
            target: "_blank"
          }
        },
        [_vm._v("vue-quill-editor")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }